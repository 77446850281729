import { BookabilityAttributes } from "@src/appV2/OpenShifts/ShiftAction";
import { ClaimShiftResponse } from "@src/appV2/OpenShifts/ShiftAction/types";
import { SEARCH_MODE } from "@src/constants";
import { Shift } from "@src/lib/interface";

import { UpdateShiftListFn } from "./custom-hooks/interfaces";
import { HcfProfileSource } from "./facilityDetails/model";
import { IShiftSlot } from "../components/workWithFriends/interface";

enum ShiftItemCategory {
  MISSING_CBH_DOCUMENTS = "MISSING_CBH_DOCUMENTS",
  BOOKING_WARNING = "BOOKING_WARNING",
  WAITING_FOR_APPROVE = "WAITING_FOR_APPROVE",
  NOT_READY_TO_BOOK_SHIFT = "NOT_READY_TO_BOOK_SHIFT",
  /**
   * We need to inform to HCP when they're booking at instantpay shift
   */
  WELCOME_TO_INSTANTPAY = "WELCOME_TO_INSTANTPAY",
  NO_LOCATION_PERMISSION = "NO_LOCATION_PERMISSION",
  // documents are expiring before end of a shift
  MISSING_CBH_DOCUMENTS_BEFORE_A_SHIFT_END = "MISSING_CBH_DOCUMENTS_BEFORE_A_SHIFT_END",
  OVERWORK_BOOKING = "OVERWORK_BOOKING",
  // these are used with the experimental - now dead - onboarding workflows but are referenced
  STRIPE_NOT_CONNECTED_AND_MISSING_HCP_DOCUMENTS = "STRIPE_NOT_CONNECTED_AND_MISSING_HCP_DOCUMENTS",
  STRIPE_NOT_CONNECTED_AND_MISSING_CBH_DOCUMENTS_BEFORE_A_SHIFT_END = "STRIPE_NOT_CONNECTED_AND_MISSING_CBH_DOCUMENTS_BEFORE_A_SHIFT_END",
}

interface ShiftDateMap {
  [date: string]: Shift[];
}

interface ResponseDetailedRatings {
  reasons: string[];
  createdAt: string;
  otherReason: string;
  rating: number;
  review: string;
  agent: {
    userId: string;
    name: string;
  };
}

interface ShiftItemViewProps extends Pick<ShiftItemProps, "showFacilityDetails" | "onItemClick"> {
  shiftDetails: Shift;
  onClickOnFacilityDetails: (displayExtraTimePayCard?: boolean) => void;
  isInstantPayAllowed: boolean;
  isUrgentShift?: boolean;
  priorityShift: boolean;
  setPriorityShift: Function;
  isShiftATeamWindowed: boolean;
  isShiftClipboardScoreWindowed: boolean;
  isWorkerClipboardScoreTargeted: boolean;
  shiftSlot?: IShiftSlot;
  isRateNegotiationActive?: boolean;
  searchMode?: SEARCH_MODE;
  hcfProfileSource?: HcfProfileSource;
  updateShiftFields?: (shift: Shift, fields: string[]) => void;
  allowedAgentReq?: string[];
  shiftBookability?: BookabilityAttributes;
  isShiftBookabilityLoading?: boolean;
  onBookShift: (shift: ClaimShiftResponse) => void;
  onShiftMarkInterest: () => void;
  isForFacilityOpenShift?: boolean;
}

interface ShiftItemProps {
  isAnimated?: boolean;
  shift: Shift;
  showFacilityDetails?: boolean;
  isInterested?: boolean;
  updateShiftList?: UpdateShiftListFn;
  isHcpInstantPayEnabled?: boolean;
  onFacilityDetailsClick?: (
    shift: Shift,
    isInstantBook: boolean,
    displayExtraTimePayCard?: boolean
  ) => void;

  onShiftClaim?: (shiftId: string, isAlreadyGrabbed?: boolean, startAt?: string) => void;
  className?: string;
  shiftPay?: number;
  setClickedInterestInShift?: (value: boolean) => void;
  isHCFInstantPayProhibited?: boolean;
  isDisabled?: boolean;
  onMarkedInterest?: (shiftId?: string) => void;
  onItemClick?: () => void;
  isUrgentShift?: boolean;
  searchMode?: SEARCH_MODE;
  availableShiftIds?: string[] | undefined;
  filterDistance?: number;
  filterDays?: number;
  hcfProfileSource?: HcfProfileSource;
  shiftSlots?: IShiftSlot[];
  updateShiftFields?: (shift: Shift, fields: string[]) => void;
  allowedAgentReq?: string[];
  shiftBookability?: BookabilityAttributes;
  isShiftBookabilityLoading?: boolean;
  isForFacilityOpenShift?: boolean;
}

interface ToolTipProps {
  toolTipHandler?: Function;
  content: any;
  buttonName?: string;
  toolTipClass?: string;
}

interface ShiftListGroupState {
  /**
   * open shifts.
   */
  open: Shift[];
  /**
   * shifts owned by the user.
   */
  assigned: Shift[];
}
interface ShiftListProps {
  day: string;
  shiftName: string;
  shifts: ShiftListGroupState;
  onClose: () => void;
  getOpenAndMyShifts: () => void;
  loadingShiftList?: boolean;
  searchMode: SEARCH_MODE;
  distancePreference: number;
  qualificationPreference: string;
  filterDays: number;
  shiftSlots?: IShiftSlot[];
  updateShiftFields?: (shift: Shift, fields: string[]) => void;
}

interface ShiftNameLoaderProps {
  listLength: number;
}

interface ShiftInlineAlertsProps {
  shift: Shift;
  /**
   * determines if agent showed interest in present shift.
   */
  interested: boolean;
}

export interface CbhDocument {
  reqId: string;
  name: string;
  visibleToHCP: boolean;
}

export {
  ShiftDateMap,
  ShiftItemProps,
  ShiftListProps,
  ShiftNameLoaderProps,
  ToolTipProps,
  ShiftItemCategory,
  ShiftInlineAlertsProps,
  ShiftItemViewProps,
  ShiftListGroupState,
  ResponseDetailedRatings,
};
